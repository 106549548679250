@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButton-textSizeLarge.huge {
  font-size: 1.2rem;
  font-weight: bold;
}

.MuiInputBase-input {
  padding: 14px 12px 0px 12px !important;
  height: 40px !important;
}
.MuiSelect-select.MuiSelect-select {
  min-width: 100px;
  padding-right: 24px !important;
  height: 33px !important;
}
.MuiInput-input {
  padding: 14px 12px 0px 12px;
  height: 40px !important;
}
.MuiInput-underline:before {
  padding: 14px 12px 0px 12px;
  height: 46px !important;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}
.MuiInput-underline.Mui-error:after {
  border: 1px solid #f44336;
}

.MuiInput-underline:after {
  padding: 14px 12px 0px 12px;
  height: 46px !important;
  border: 2px solid #5bb86f;
  border-radius: 8px;
}

/* INPUT BOX WHITE */
.MuiInput-underline.box:before {
  border: 0px !important;
}
.MuiInputBase-root.box .MuiInput-input {
  padding: 2px 12px 0px 12px;
  background-color: #fff;
  height: 40px;
  border-radius: 8px;
  color: black;
}
.MuiInputBase-root.box.MuiInput-underline:before {
  border: 0px !important;
  border-radius: 8px;
}
.MuiInputBase-root.box.MuiInput-underline:after {
  border: 0px !important;
  border-radius: 8px;
}

/* INPUT BOX HUGE WHITE */
.MuiInputBase-root.box.huge .MuiInput-input {
  padding: 6px 12px 0px 12px;
  background-color: #fff;
  height: 55px;
  border-radius: 8px;
  color: black;
  font-size: 1.6rem;
}

/* INPUT BOX SLIM WHITE */
.MuiInput-underline.slim:before {
  border: 0px !important;
}
.MuiInputBase-root.slim .MuiInput-input {
  padding: 6px 12px 0px 12px !important;
  background-color: #fff;
  height: 38px !important;
  border-radius: 8px;
  color: black;
}
/* .MuiInputBase-root.slim .MuiInput-input {
  padding: 6px 12px 0px 12px !important;
  background-color: #fff;
  height: 38px !important;
  border-radius: 8px;
  color: black;
}
.MuiInputBase-root.slim .MuiInputBase-input {
  height: 38px !important;
} */

.MuiInputBase-root.time-input .MuiInput-input {
  padding: 0px !important;
  width: 4rem !important;
  height: 4rem !important;
  text-align: center;
  font-weight: bold;
  background-color: #fff;
  border-radius: 8px;
  color: black;
  font-size: 2rem;
}
.MuiInputBase-root.time-input.MuiInput-underline:before {
  border: 0px;
  border-radius: 8px;
}
.MuiInputBase-root.time-input.MuiInput-underline:after {
  border: 0px !important;
  border-radius: 8px;
}

.searchResultsContainer {
  position: relative;
  width: 100%;
  left: 0px;
  will-change: transform, opacity;
}

.searchResultsBox {
  position: relative;
  min-height: 45vh;
  margin-bottom: 60px;
  border-radius: 8px;
  opacity: 0.64;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.8);
  background: linear-gradient(135deg, #cfe0fc 0%, #949daa 100%);
}

.infoBoxContainer {
  position: relative;
  width: 100%;
  left: 0px;
  will-change: transform, opacity;
}
.infoBox {
  position: relative;
  min-height: 15vh;
  margin-bottom: 60px;
  border-radius: 8px;
  opacity: 0.72;
  padding: 2.2rem;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.8);
  background: linear-gradient(135deg, #fff 0%, #f3f8ff 100%);
}
.infoBox .MuiTypography-root {
  color: rgba(0, 0, 0, 0.8) !important;
}

.infoBox.danger {
  background: linear-gradient(135deg, #e33225 0%, #f55447 100%);
}
.infoBox.danger .MuiTypography-root {
  color: #fff !important;
  font-weight: 700 !important;
}

.searchResult {
  padding: 8px;
  flex-grow: 1;
  width: 100%;
}

.serviceBoxContainer {
  position: relative;
  width: 100px;
  /* left: 0px; */
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  will-change: transform, opacity;
}
.serviceBox {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  opacity: 0.72;
  padding: 2.2rem;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.8);
  background: linear-gradient(135deg, #ccc 0%, #73787f 100%);
}

.circleWithIcon {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: #000;
  border-radius: 100%;
}

.d-inline {
  display: inline;
}

.MuiAppBar-positionFixed {
  right: unset !important;
}

.useSecondaryText[class*='MuiTypography'] {
  color: #000 !important;
}
.useSecondaryText [class*='MuiTypography'] {
  color: #000 !important;
}
.useSecondaryText[class*='MuiListItemText'] {
  color: #000 !important;
}
.useSecondaryText [class*='MuiListItemText'] {
  color: #000 !important;
}

.dividerWhite {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.uppercaseBold {
  font-weight: bold !important;
  text-transform: uppercase;
}

.FormGroup {
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #000;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 14px 15px 11px 12px;
  border-radius: 8px;
  background: #fff;
  height: 44px;
}

.qr-scanner-container {
  border-radius: 6%;
  width: 466px;
  overflow: hidden;
  height: 351px;
  position: relative;
}

.qr-scanner-container-2 {
  border-radius: 6%;
  width: 466px;
  height: 364px;
  left: 0;
  top: -21px;
  position: absolute;
}

.qr-scanner-container video {
  margin-top: -37px;
}

.custom-flags {
  display: flex;
  flex-direction: column;
  overflow: 'auto';
  justify-content: 'center';
  align-items: center;
}
.custom-flags ul {
  background-color: #2a2b2f;
  margin-top: 28px;
  margin-left: -7px;
  width: 88px;
}
.custom-flags button {
  border: thin solid #fff;
  color: #fff;
  padding: 0px;
  padding-right: 5px;
  font-size: 10px !important;
}
.custom-flags button:disabled {
  border: thin solid #fff;
  color: #fff;
  padding: 0px;
  padding-right: 5px;
  font-size: 10px !important;
  background-color: #ababab;
}
