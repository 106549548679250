@keyframes spin360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #2a2b2f;
  z-index: 999;
}
.loader-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.loader2 {
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
}

@keyframes load7 {
  100% {
    transform: rotatez(360deg);
  }
}
.loader7::before {
  content: "";
  color: white;
  height: 65px;
  width: 65px;
  background: transparent;
  border-radius: 50%;
  border: 10px solid #56c26d;
  border-color: #8af59c #189140;
  animation: load7 0.6s infinite ease-in-out;
  box-shadow: 0px 0px 40px -2px greenyellow;
}
